import React from 'react';
import { Navigate } from 'react-router-dom/dist';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase';

interface ProtectedRouteProps {
  component: React.ComponentType<any>;
}

function ProtectedRoute({ component: Component, ...rest }: ProtectedRouteProps): JSX.Element {
    const [user, loading, error] = useAuthState(auth);

    if (loading) {
      return <div>Loading...</div>;
    }
  
    if (error) {
      console.error(error);
      return <div>Error</div>;
    }

    if (user === null) {
        return <Navigate to={"/"} replace={true}/>;
    }
  
    return <Component />;
  }

export default ProtectedRoute;
