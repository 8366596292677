interface Props {
    alt: string;
    picture: string;
}

export function ImageBlock(props: Props) {
    return (

        <div className="w-full h-full break-words text-center text-white text-xl">
            <div className="relative w-full h-full">
                <picture>
                    <img
                        alt={props.alt}
                        className="w-full h-full rounded-2xl object-center object-cover ring-1 ring-gray-300"
                        src={props.picture}
                        >
                    </img>
                </picture>
                <h5 className="absolute w-full h-fit bottom-1 flex flex-col justify-end ">{props.alt}</h5>
            </div>
        </div>
    );
}

