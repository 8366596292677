import { ChangeEventHandler } from "react";
import {
    Input,
    Ripple,
    initTWE,
} from "tw-elements";

interface Props {
    type: "text" | "password" | "email" | "datetime-local" | "date" | "URL" | "number";
    id: string;
    title: string;
    placeholder: string;
    value: string | number | readonly string[];
    onChange: ChangeEventHandler<HTMLInputElement>;
    required?: boolean;
    inputMode?: "none" | "text" | "tel" | "url" | "email" | "numeric" | "decimal" | "search";
    autoComplete?: "hidden" | "text" | "search" | "url" | "tel" | "email" | "date" | "month" | "week" | "time" | "datetime-local" | "number" | "range" | "color" | "password";
    pattern?: string;
    min?: any;
    max?: any;
    minLength?: number;
    maxLength?: number;

}

export function SimpelInput(props: Props) {
    initTWE({ Input, Ripple });
    return (
        <div className="relative mb-4 w-full" data-te-input-wrapper-init>
            <input
                type={props.type}
                className="peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                id={props.id}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange}
                required={props.required}
                inputMode={props.inputMode}
                autoComplete={props.autoComplete}
                pattern={props.pattern}
                min={props.min}
                max={props.max}
                minLength={props.minLength}
                maxLength={props.maxLength} />
            <label
                htmlFor={props.id}
                className="pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none"
            >{props.title}
            </label>
        </div>
    );
}