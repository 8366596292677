import Header from "../../partials/Header";
import { useState, useEffect, useRef, ChangeEvent } from "react"
import Sidebar from "../../partials/Sidebar";
import { db } from "../../firebase";
import { onValue, push, ref } from "firebase/database";
import { FirebasePresentesOptions } from "../../types/firebase.types";
import { EditPresentsCard } from "../../components/card/presentescard";
import { BasicButton } from "../../components/BasicButton";
import { UserPlusIcon } from "../../components/Icons/UserPlus";
import { UserIcon } from "../../components/Icons/User";
import processImage from "../../utils/proxessImage";
interface Props {
    state?: boolean;
}

function AdminPresenters(props: Props) {
    const [data, setData] = useState<FirebasePresentesOptions>(Object);
    const [newData, setNewData] = useState<FirebasePresentesOptions>({ image: "", name: "", text: "", active: false });
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const dialogRef = useRef<HTMLDialogElement>(null);

    async function getPresenters() {
        const starCountRef = ref(db, 'presents/');
        await onValue(starCountRef, (snapshot) => {
            setData(snapshot.val());
        });

    }

    useEffect(() => {
        if (props.state) {
            setSidebarOpen(!sidebarOpen);
        }
        getPresenters();
    }, [props.state, sidebarOpen])

    function openDialog() {
        if (dialogRef.current) {
            dialogRef.current.showModal();
        }
    };

    function closeDialog() {
        setNewData({
            image: "",
            name: "",
            text: "",
            active: false
        })
        if (dialogRef.current) {
            dialogRef.current.close();
        }
    };

    async function handleSave() {
        if (!newData.name || !newData.text) {
            return;
        }

        try {
            await push(ref(db, "presents/"), {
                alt: `${newData.name} picture`,
                name: newData.name,
                text: newData.text,
                image: newData.image,
                tempImage: newData.image,
                active: false
            });
            setNewData({
                image: "",
                name: "",
                text: "",
                active: false
            });
            <AdminPresenters state={false} />
        } catch (error) {
            console.log(error);
        }
        if (dialogRef.current) {
            dialogRef.current.close();
        }
    }

    async function onChangeInput(event: ChangeEvent<HTMLInputElement & HTMLTextAreaElement>) {
        const name = event.target.name;
        const value = event.target.value;
        const file = event.target.files?.[0] || null;

        if (file) {
            const base64Image = await processImage(file);
            setNewData({
                ...newData,
                image: base64Image
            })
        } else {
            setNewData({
                ...newData,
                [name]: value
            })
        }
    }


    return (
        <div className="flex h-screen overflow-hidden">

            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />


            {/* Content area */}
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                {/*  Site header */}
                <div className="z-30 sticky top-0 gap-10 grid">
                    <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} >
                        <BasicButton onClick={openDialog} variant="default" icon={<UserPlusIcon className="w-6" />} />
                    </Header>
                    <dialog ref={dialogRef} className="w-fit border shadow-xl rounded-3xl p-5 bg-neutral-300">
                        <div className="grid grid-cols-3 w-full">
                            <div />
                            <div className="w-full flex justify-center pb-2">
                                <label className="w-32 h-32 bg-gray-200 rounded-full grid hover:bg-slate-400">
                                    {
                                        newData.image
                                            ?
                                            <img src={newData.image} className="object-center rounded-full w-32" alt={newData.name + " Picture"} />
                                            :
                                            <UserIcon className="w-28 h-28 justify-self-center" />
                                    }
                                    <input required type="file" name="image" accept="image/png, image/jpeg" className="hidden" onChange={onChangeInput} />
                                </label>
                            </div>
                        </div>
                        <div className="flex flex-row justify-center">
                            <input required className="max-w-md w-full p-1 text-center h-auto rounded-2xl" name="name" value={newData.name} onChange={onChangeInput} />
                        </div>
                        <div className="flex flex-row justify-center mx-10 my-5 bg-zinc-300 h-fit w-fit max-w-xl rounded-3xl">
                            <textarea required className="w-full p-1 h-fit rounded-2xl" value={newData.text} name="text" onChange={onChangeInput} rows={10} cols={58} />
                        </div>
                        <div className="flex flex-row justify-center gap-2">
                            <BasicButton onClick={closeDialog} variant="close" name="Close" />
                            <BasicButton onClick={handleSave} variant="save" name="Save" />
                        </div>
                    </dialog>
                </div>
                <main className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto flex flex-col justify-center">
                    {
                        data
                            ?
                            Object.entries(data).map((keyName) => {
                                const arrayOfPresentsData = Object.call(data, keyName);
                                const presentsData: FirebasePresentesOptions = arrayOfPresentsData[1];
                                return (
                                    <EditPresentsCard data={{ name: presentsData.name, image: presentsData.tempImage!, text: presentsData.text, active: presentsData.active }} index={keyName[0]} key={presentsData.name} image={presentsData.image} />
                                )
                            }
                            )
                            :
                            <></>
                    }
                </main>
            </div>
        </div>
    );

}

export default AdminPresenters;