import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom/dist';
import './i18n';
import './index.css';
import App from './App';
import { Carousel, initTWE } from "tw-elements";
initTWE({ Carousel });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const styleElement = (
  <div className='h-full w-full'>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </div>
)
root.render(styleElement);
