import { LoginLogo } from "./Logo";
import { SimpelButton } from "./SimpelButton";
import { ChangeEventHandler } from "react";
import { SimpelInput } from "./SimpelInput";

interface Props {
    OnSubmit: any;
    title: string;
    onChangeEmail: ChangeEventHandler<HTMLInputElement>;
    onChangePassword: ChangeEventHandler<HTMLInputElement>;
    password: string;
    email: string;
}

export function LoginForm(props: Props) {
    return (
        <div className="px-4 md:px-0 lg:w-6/12">
            <div className="md:mx-6 md:p-12">
                <LoginLogo image="/logoTrans.webp" title="We are The Festival Team" />
                <form onSubmit={props.OnSubmit}>
                    <p className="mb-4">{props.title}</p>
                    <SimpelInput autoComplete="email" inputMode="email" required={true} value={props.email} type="email" id="email" placeholder="Email" title="Email" onChange={props.onChangeEmail} />
                    <SimpelInput autoComplete="password" inputMode="text" required={true} value={props.password} type="password" id="password" placeholder="Password" title="Password" onChange={props.onChangePassword} />
                    <SimpelButton text="Log in" type="submit" />
                </form>
            </div>
        </div>

    )
}