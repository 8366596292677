import clsx from "clsx";

interface Props {
    className?: string;
    text: string;
    textSmall?: string;
    icon?: any;
}

export function Header2(props: Props) {
    return (
        <h2 className={clsx("text-[#03A7CB] text-4xl font-bold text-center", props.className)}>{props.text}</h2>

    )
}

export function Header3(props: Props) {
    return (
        <h3 className={clsx("text-[#03A7CB] text-3xl font-bold text-center", props.className)}>{props.text}</h3>

    )
}

export function AdminSideBarHeader(props: Props) {
    return (
        <div className="flex items-center">
            {props.icon}
            <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                {props.text}
            </span>
        </div>
    )
}

export function AdminSideBarUnderHeader(props: Props) {
    return (
        <h3 className="text-xs uppercase text-slate-500 font-semibold pl-3">
            <span className="hidden lg:block lg:sidebar-expanded:hidden 2xl:hidden text-center w-6" aria-hidden="true">
                {props.textSmall}
            </span>
            <span className="lg:hidden lg:sidebar-expanded:block 2xl:block">{props.text}</span>
        </h3>
    )
}