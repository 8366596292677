import { useState, useEffect, Dispatch, SetStateAction } from 'react';

interface PopupProps {
  message: string;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  timeout?: number;
  onClose: () => void;
}

export function Popup(props: PopupProps) {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  useEffect(() => {
    let timerId: NodeJS.Timeout;
    setIsOpen(props.isOpen);
    if (isOpen) {
      timerId = setTimeout(() => {
        setIsOpen(false);
      }, props.timeout ? props.timeout : 5000);
    }
    return () => clearTimeout(timerId);
    // eslint-disable-next-line
  }, [props.onClose]);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  function handleClosePopup() {
    setIsOpen(false);
    if (props.onClose) {
      props.onClose();
    }
  }

  return (
    <>
      {isOpen && (
        <div className="bg-green-200 p-4 rounded-md mt-10 w-fit flex flex-row space-x-3">
          <p className="text-green-800 font-semibold">{props.message}</p>
          <button onClick={handleClosePopup}>Close</button>
        </div>
      )}
    </>
  );
}
