import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyB26pgDFiqjiGahu3Rs35jaQIB2FHowt1k",
    authDomain: "my-projeckt-7fb57.firebaseapp.com",
    databaseURL: "https://my-projeckt-7fb57-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "my-projeckt-7fb57",
    storageBucket: "my-projeckt-7fb57.appspot.com",
    messagingSenderId: "1030645989461",
    appId: "1:1030645989461:web:ec0483af983ecf576e14dc",
    measurementId: "G-Z1GTG1P2EM"
};

export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const db = getDatabase(app);
export const auth = getAuth(app);