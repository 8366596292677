import clsx from 'clsx';
import { useEffect, useState } from 'react';
import logo from '../../images/logo.png';
import { useLocation } from 'react-router-dom/dist';

function Navbar() {
    const [state, setState] = useState<boolean>();
    const location = useLocation();
    const locationPath: string = location.pathname.split("/")[1];

    useEffect(() => {
        setState(false);
    }, []);

    const onClickHamburger = () => {
        setState(state ? false : true);
    }

    if (location.pathname.split("/")[1] === "admin") {
        return <></>;
    }

    return (
        <nav className='flex flex-row bg-slate-100 h-14 items-center justify-between lg:justify-start print:hidden'>
            <div className='z-40 flex mt-2 mb-2'>
                <a className="select-none" href="./">
                    <img src={logo} alt="logo" className='object-scale-down shadow rounded-[17px] align-middle top-px w-12 ml-1' />
                </a>

                <div className={clsx(locationPath === "contact" && 'text-white', 'hidden space-x-0 leading-none ml-1 lg:flex lg:flex-col')}>
                    <div> Danish </div>
                    <div> Shibari </div>
                    <div> Festival </div>
                </div>
            </div>
            <div className={clsx(state ? '' : "hidden", 'select-none z-40 absolute z top-[3.25rem] flex-col pr-1 space-y-1 flex lg:pl-5 lg:pt-0 lg:flex lg:flex-row lg:space-x-3 lg:top-0 lg:static max-lg:left-auto max-lg:right-px')}>
                <div></div>
                <a href="/about" className={clsx(locationPath === 'about' ? 'bg-gray-500 cursor-default' : "bg-gray-700", 'h-full px-3 text-center text-white rounded-md pt-1 pb-2 shadow-xl hover:bg-gray-500')}>
                    About
                </a>
                <a href="/presenters" className={clsx(locationPath === 'presenters' ? 'bg-gray-500 cursor-default' : "bg-gray-700", 'h-full px-3 text-center text-white rounded-md pt-1 pb-2 shadow-xl hover:bg-gray-500')}>
                    Presenters
                </a>
                <a href="/schedule" className={clsx(locationPath === 'schedule' ? 'bg-gray-500 cursor-default' : "bg-gray-700", 'h-full px-3 text-center text-white rounded-md pt-1 pb-2 shadow-xl hover:bg-gray-500')}>
                    Schedule
                </a>
                <a href="/venue" className={clsx(locationPath === 'venue' ? 'bg-gray-500 cursor-default' : "bg-gray-700", 'h-full px-3 text-center text-white rounded-md pt-1 pb-2 shadow-xl hover:bg-gray-500')}>
                    Venue
                </a>
                <a href="/previous" className={clsx(locationPath === 'previous' ? 'bg-gray-500 cursor-default' : "bg-gray-700", 'h-full px-3 text-center text-white rounded-md pt-1 shadow-xl pb-2 hover:bg-gray-500')}>
                    Previous Festivals
                </a>
                <a aria-disabled href="/tickets" className={clsx(locationPath === 'tickets' ? 'bg-gray-500 cursor-default' : "bg-gray-700", 'h-full px-3 text-center text-white rounded-md pt-1 pb-2 shadow-xl hover:bg-gray-500')}>
                    Tickets
                </a>
                <a href="/rules" className={clsx(locationPath === 'rules' ? 'bg-gray-500 cursor-default' : "bg-gray-700", 'h-full px-3 text-center text-white rounded-md pt-1 pb-2 shadow-xl hover:bg-gray-500')}>
                    Rules
                </a>
                <a href="/contact" className={clsx(locationPath === 'contact' ? 'bg-gray-500 cursor-default' : "bg-gray-700", 'h-full px-3 text-center text-white rounded-md pt-1 pb-2 shadow-xl hover:bg-gray-500')} >
                    Contact
                </a>
            </div>
            <button className='lg:hidden z-20 hover:text-gray-700 mr-2' onClick={onClickHamburger}>
                <svg xmlns="http://www.w3.org/2000/svg" className={clsx(locationPath === "contact" && 'text-white' ,"h-6 w-6 cursor-pointer block lg:hidden")} fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                </svg>
            </button>
        </nav>
    )
}


export default Navbar