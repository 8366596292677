interface Props {
    title: string;
    text: string;
}

export function LoginDescription(props: Props) {
    return (
        <div
            className="flex items-center rounded-b-lg lg:w-6/12 lg:rounded-r-lg lg:rounded-bl-none"
            style={{ background: "linear-gradient(to right, #764400, #BF8100, #fff95b)" }}>
            <div className="px-4 py-6 text-white md:mx-6 md:p-12">
                <h4 className="mb-6 text-xl font-semibold">
                    {props.title}
                </h4>
                <p className="text-sm">
                    {props.text}
                </p>
            </div>
        </div>

    )
}