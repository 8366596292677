import { NavLink } from "react-router-dom/dist";

interface Props {
    url: string;
    text: string;
}

export function BasicUnderElementLink(props: Props) {
    return (
        <li className="mb-1 last:mb-0">
            <NavLink end to={props.url} className="block text-slate-400 hover:text-slate-200 transition duration-150 truncate">
                <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                    {props.text}
                </span>
            </NavLink>
        </li>
    );
}