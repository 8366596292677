import { useEffect, useState } from "react";
import { onValue, ref } from 'firebase/database';
import { db } from "../firebase";
import Navbar from "../components/Nav/Navbar";
import { TicketIcon } from "../components/Icons/Ticket";

function Tickets() {
    const [link, setLink] = useState("callback");
    const linkIsSomething = link !== "" && link !== "callback";

    async function getTicketLink(): Promise<void> {
        const starCountRef = ref(db, 'tickets/url');
        await onValue(starCountRef, (snapshot) => {
            setLink(snapshot.val());
        });
        return;
    }

    async function onPageLoad() {
        await getTicketLink();
        if (linkIsSomething) {
            window.location.replace(link);
            const timeout = setTimeout(() => {
                // 👇️ redirects to an external URL
                window.location.replace(link);
            }, 3000);
            return () => clearTimeout(timeout);
        }

    }

    useEffect(() => {
        onPageLoad();
    })

    if (linkIsSomething) {
        return (
            <center>Will redirect in 3 seconds...</center>
        );
    } else {
        return (
            <>
                <Navbar />
                <center className="h-full">
                    <div className="mt-10 h-full text-4xl font-black">
                        <h1>Tickets will go on sale soon</h1>
                        <TicketIcon className="md:w-80 w-28"/>
                    </div>
                </center>
            </>
        )
    }
    return <></>;
    

}

export default Tickets;