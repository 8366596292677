interface Props {
    image: string;
    title: string;
}

export function LoginLogo(props: Props) {
    return (
        <div className="text-center">
            <img
                className="mx-auto w-48 rounded-full"
                src={props.image}
                alt="logo" />
            <h4 className="mb-12 mt-1 pb-1 text-xl font-semibold">
                {props.title}
            </h4>
        </div>
    );
}