import { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { AdminSideBarUnderHeader } from '../components/text/Header';
import SideBarElementGroup from '../components/Nav/SideBarElementGroup';
import { BasicAuthIcon } from '../components/Icons/BasicAuth';
import { BasicDashboardIcon } from '../components/Icons/BasicDashboard';
import { BasicECommerceIcon } from '../components/Icons/BasicECommerce';
import { BasicCommunityIcon } from '../components/Icons/BasicCommunity';
import { BasicFinanceIcon } from '../components/Icons/BasicFinance';
import { BasicScopeIcon } from '../components/Icons/BasicScope';
import { BasicTaskIcon } from '../components/Icons/BasicTask';
import { BasicStarsIcon } from '../components/Icons/BasicStars';
import SideBarLink from '../components/Nav/SidebarLink';
import { BasicCalendarIcon } from '../components/Icons/BasicCalendar';
import { BasicSettingsIcon } from '../components/Icons/BasicSettings';
import { BasicUtilityIcon } from '../components/Icons/BasicUtility';
import { AdminLogoIcon } from '../components/Icons/AdminLogo';

function Sidebar({ sidebarOpen, setSidebarOpen }) {
  const trigger = useRef(null);
  const sidebar = useRef(null);

  const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
  const [sidebarExpanded, setSidebarExpanded] = useState(storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true');

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (!sidebarOpen || sidebar.current.contains(target) || trigger.current.contains(target)) return;
      setSidebarOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {
    localStorage.setItem('sidebar-expanded', sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector('body').classList.add('sidebar-expanded');
    } else {
      document.querySelector('body').classList.remove('sidebar-expanded');
    }
  }, [sidebarExpanded]);

  return (
    <div>
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
          }`}
        aria-hidden="true"
      ></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64 2xl:!w-64 shrink-0 bg-slate-800 p-4 transition-all duration-200 ease-in-out ${sidebarOpen ? 'translate-x-0' : '-translate-x-64'
          }`}
      >
        {/* Sidebar header */}
        <div className="flex justify-between mb-10 pr-3 sm:px-2">
          {/* Close button */}
          <button
            ref={trigger}
            className="lg:hidden text-slate-500 hover:text-slate-400"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          {/* Logo */}
          <NavLink end to="/" className="block">
            <AdminLogoIcon />
          </NavLink>
        </div>

        {/* Links */}
        <div className="space-y-8">
          {/* Pages group */}
          <div>
            <ul className="mt-3">
              <AdminSideBarUnderHeader text='Pages' textSmall='•••' />
              <SideBarLink icon={<BasicDashboardIcon />} pathname='fNhzjfq1Jrd8GgkQ6w3x' title="Dashboard" url="/admin/fNhzjfq1Jrd8GgkQ6w3x" />
              <SideBarLink icon={<BasicAuthIcon />} pathname='prevFestival' title="PrevFestival Comming" url="/admin/fNhzjfq1Jrd8GgkQ6w3x" />
              <SideBarLink icon={<BasicCommunityIcon />} pathname='DZkTnw8f95Xd6Z6nzRqf' title="Presenters" url="/admin/DZkTnw8f95Xd6Z6nzRqf" />
              {/* Job Board */}
              <SideBarElementGroup type="multi" icon={<BasicScopeIcon />} pathname='V2bDwNXhgYF43CgMRyda' title="Venue Comming"
                underElemt={[["Venue List", "/admin/V2bDwNXhgYF43CgMRyda"], ["Add Venue", "/venue"]]} />
              {/* Calendar */}
              <SideBarLink icon={<BasicCalendarIcon />} pathname='schedule' title='Schedule Comming' url='/admin/fNhzjfq1Jrd8GgkQ6w3x' />
              <SideBarLink icon={<BasicSettingsIcon />} pathname='4jycdWB246yTUofSRD3W' title="Rules" url="/admin/4jycdWB246yTUofSRD3W" />
              <SideBarLink icon={<BasicUtilityIcon />} pathname='about' title="About Comming" url="/admin/fNhzjfq1Jrd8GgkQ6w3x" />
            </ul>
            <ul className="mt-3">
              <AdminSideBarUnderHeader text='More' textSmall='•••' />
              {/* E-Commerce */}
              <SideBarElementGroup type="multi" icon={<BasicECommerceIcon />} pathname='ecommerce' title="E-Commerce"
                underElemt={[["Event", "https://myshibari.dk/dk/begivenheder/"], ["Event Admin", "https://myshibari.dk/wp-admin/edit.php?post_type=mec-events"],
                ["Add Event", "https://myshibari.dk/wp-admin/post-new.php?post_type=mec-events"],
                ["Invoices - Event", "https://myshibari.dk/wp-admin/edit.php?post_type=mec-books"], ["Shop", "http://shop.myshibari.dk/"],
                ["Shop Admin", "https://shop.myshibari.dk/admin"],]} />
              {/* Finance */}
              <SideBarElementGroup type="multi" icon={<BasicFinanceIcon />} pathname='finance' title="Finance"
                underElemt={[["Pay - Stripe", "https://dashboard.stripe.com/login"]]} />
              {/* Tasks */}
              <SideBarLink icon={<BasicTaskIcon />} pathname='tasks' title='Tasks' url='https://trello.com/b/W7Lbri8w/danish-shibari-festival-website' />
              {/* Campaigns */}
              <SideBarElementGroup type="multi" icon={<BasicStarsIcon />} pathname='campaigns' title="Campaigns"
                underElemt={[["Facebook", "https://business.facebook.com/latest/home"], ["Google Ad", "https://ads.google.com/"],
                ["Google Analytics", "https://analytics.google.com/analytics/web/#/p301703166/reports/intelligenthome"]]} />
            </ul>
          </div>
        </div>

        {/* Expand / collapse button */}
        <div className="pt-3 hidden lg:inline-flex 2xl:hidden justify-end mt-auto">
          <div className="px-3 py-2">
            <button onClick={() => { setSidebarExpanded(!sidebarExpanded); console.log(sidebarExpanded) }}>
              <span className="sr-only">Expand / collapse sidebar</span>
              <svg className="w-6 h-6 fill-current sidebar-expanded:rotate-180" viewBox="0 0 24 24">
                <path className="text-slate-400" d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z" />
                <path className="text-slate-600" d="M3 23H1V1h2z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
