interface Props {
    children: any;
    open: any;
}

export default function BasicUnderElement(props: Props) {
    return (
        <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
            <ul className={`pl-9 mt-1 ${!props.open && 'hidden'}`}>
                {props.children}
            </ul>
        </div>
    );
}