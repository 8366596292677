import { SetStateAction, useEffect, useState, FormEvent } from 'react';
import Header from '../../partials/Header';
import Sidebar from '../../partials/Sidebar';
import WelcomeBanner from '../../components/block/WelcomeBanner';
import DashboardCard from '../../components/dashboard/DashboardCard';
import { SimpelInput } from '../../components/login/SimpelInput';
import { onValue, ref, set } from 'firebase/database';
import { db } from '../../firebase';
import dayjs from 'dayjs';
import { Popup } from '../../components/modal/Popup';
import { BasicButton } from '../../components/BasicButton';

function Dashboard() {
  var customParseFormat = require('dayjs/plugin/customParseFormat')
  dayjs.extend(customParseFormat)
  const [sidebarOpen, setSidebarOpen] = useState(false);


  const [date, setDate] = useState(dayjs().format("YYYY-DD-MM"));
  const [link, setLink] = useState("miscgang");
  const [startDay, setStartDay] = useState(new Date().getDay());
  const [showPopup, setShowPopup] = useState(false);



  async function getHomeDate() {
    const starCountRef = ref(db, 'home/date');
    await onValue(starCountRef, (snapshot) => {
      setDate(dayjs(snapshot.val().split("-")[1], "DD.MM.YYYY").format("YYYY-MM-DD"));
      setStartDay(snapshot.val().split("-")[0]);
    });

  }

  async function getTicketLink() {
    const starCountRef = ref(db, 'tickets/url');
    await onValue(starCountRef, (snapshot) => {
      setLink(snapshot.val());
    });

  }

  useEffect(() => {
    getHomeDate();
    getTicketLink();
  }, [])

  function handleLinkChange(event: { target: { value: SetStateAction<string>; }; }) {
    setLink(event.target.value);
  }

  function handleStartDayChange(event: { target: { value: string; }; }) {
    setStartDay(parseInt(event.target.value));
  }

  function handleEndDateChange(event: { target: { value: SetStateAction<string>; }; }) {
    setDate(event.target.value);
  }

  async function onSubmitLink(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const target = event.target as typeof event.target & {
      url: { value: string };
    };
    try {
      await set(ref(db, 'tickets'), {
        url: target.url.value,
      });
      setShowPopup(true);
    } catch (error) {
      console.log(error);
    }
  }

  async function onSubmitDate(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const target = event.target as typeof event.target & {
      startDay: { value: string };
      endDate: { value: string };
    };
    try {
      await set(ref(db, 'home'), {
        date: `${target.startDay.value}-${dayjs(target.endDate.value, "YYYY-MM-DD").format("DD.MM.YYYY")}`,
      });
      setShowPopup(true);
    } catch (error) {
      console.log(error);
    }
  }


  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />


      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} >
          {showPopup && <Popup message="Successful saved" timeout={5000} onClose={() => setShowPopup(false)} isOpen={showPopup} setIsOpen={setShowPopup} />}
        </Header>


        <main className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

          {/* Welcome banner */}
          <WelcomeBanner />

          {/* Cards */}
          <div className="grid grid-cols-12 gap-6">
            <DashboardCard title='Event Date' info="Edit the Date \n Displayed on the Home Page">
              <form onSubmit={onSubmitDate} className='flex flex-col mb-1'>
                <div className='mx-3 flex flex-row justify-between space-x-1'>
                  <SimpelInput min={0} max={31} placeholder='DD' value={startDay} minLength={2} maxLength={2} autoComplete='number' title='Start Day' onChange={handleStartDayChange} inputMode='numeric' type='number' id='startDay' pattern='(0[1-9]|1[0-9]|2[0-9]|3[01])' />
                  <SimpelInput min={dayjs().format("YYYY-MM-DD")} placeholder='DD-MM-YYYY' value={date} autoComplete='date' title='End Date' onChange={handleEndDateChange} type='date' id='endDate' pattern='(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[1-9]|1[012])-[0-9]{4}' />
                </div>
                <div className='flex flex-row justify-center'>
                  <BasicButton name='Gem' variant='save' type='submit' />
                </div>
              </form>
            </DashboardCard>
            {/* Line chart (Real Time Value) */}
            <DashboardCard title='Ticket Link' info='Edit Ticket Link \n The site \n Poeple will be redirect \n For buying tickets'>
              <div className='mx-3'>
                <form onSubmit={onSubmitLink}>
                  <SimpelInput placeholder='Link' value={link} autoComplete='url' title='Link' onChange={handleLinkChange} inputMode='url' type='URL' id='url' pattern='[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?' />
                  <div className='flex flex-row justify-center'>
                    <BasicButton name='Gem' variant='save' type='submit' />
                  </div>
                </form>
              </div>
            </DashboardCard>
          </div>

        </main>

      </div>
    </div>
  );
};

export default Dashboard;