import Info from "../Info";

interface Props {
  title: string;
  info: string;
  children: any;
}

function DashboardCard(props: Props) {
  const newText = props.info.split('\\n').map(str => <p key={str}>{str}</p>);

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 bg-white shadow-lg rounded-sm border border-slate-200">
      <header className="px-5 py-4 border-b border-slate-100 flex items-center">
        <h2 className="font-semibold text-slate-800">{props.title}</h2>
        <Info className="ml-2" containerClassName="min-w-44">
          <div className="text-sm text-center">
            {newText}
          </div>
        </Info>
      </header>
      {props.children}
    </div>
  );
};

export default DashboardCard;
