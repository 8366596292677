import { FormEvent, useEffect, useRef, useState } from "react";
import { BasicButton } from "../BasicButton";
import { ref, remove, update } from "firebase/database";
import { db } from "../../firebase";
import AdminPresenters from "../../pages/admin/Presenters";
import SaveModal from "../modal/Save";
import processImage from "../../utils/proxessImage";
import { PlusIcon } from "../Icons/Plus";
import { FirebasePresentesOptions } from "../../types/firebase.types";

interface Props {
    index?: number | string;
    image?: string;
    tempImage?: String;
    data: FirebasePresentesOptions;
}

const alt: string = " Picture"

export function PresentsCard(props: Props) {
    const newText = props.data.text.replace(/\\n/g, "\n");
    if (props.data.text) {
        return (
            <div className="mt-4 mb-4" key={props.data.name}>
                <div className="flex flex-row justify-center">
                    <img src={props.data.image} className="object-center rounded-full w-32" alt={props.data.name + alt} />
                </div>
                <h2 className="font-akaya text-3xl italic text-center my-2">{props.data.name}</h2>
                <div className="flex flex-row justify-center">
                    <p className="bg-zinc-300 h-fit w-fit max-w-xl rounded-3xl px-10 py-5 whitespace-pre-wrap">{newText}</p>
                </div>
    
            </div>
        );
    }
    return (
        <div className="mt-4 mb-4" key={props.data.name}>
            <div className="flex flex-row justify-center">
                <img src={props.data.image} className="object-center rounded-full w-32 h-32" alt={props.data.name + alt} />
            </div>
            <h2 className="font-akaya text-3xl italic text-center my-2">{props.data.name}</h2>
        </div>
    );
    
}

export function EditPresentsCard(props: Props) {
    const newText = props.data.text.replace(/\\n/g, "\n");

    const [text, setText] = useState(newText);
    const [title, setTitle] = useState(props.data.name);
    const [alertMessage, setAlertMessage] = useState("");
    const [image, setImage] = useState(props.data.image);
    const [active, setActive] = useState(props.data.active);
    const [buttonOneText, setButtonOneText] = useState("");
    const [buttonTwoText, setButtonTwoText] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const spanRef = useRef<HTMLSpanElement>(null);
    const [modalMode, setModalMode] = useState("")


    useEffect(() => {
        if (textareaRef.current && spanRef.current) {
            spanRef.current.textContent = textareaRef.current.value;
        }
    }, [text])

    async function handleChange(event: React.ChangeEvent<HTMLTextAreaElement & HTMLInputElement>) {
        const file = event.target.files?.[0] || null;

        if (file) {
            const base64Image = await processImage(file);
            setImage(base64Image);
        } else {
            setText(event.target.value);
        }
    };

    const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
    };

    function handleCheck(event: FormEvent<HTMLButtonElement>, alertMessage: string, buttonOneText: string, buttonTwoText: string, modalMode: "save" | "delete" | "" = "") {
        setButtonOneText(buttonOneText);
        setButtonTwoText(buttonTwoText);
        setAlertMessage(alertMessage);
        setModalMode(modalMode);
        setIsOpen(true);
    };

    function handleActivate() {
        setActive(!!!active)
    }

    async function handleDelete() {
        try {
            await remove(ref(db, `presents/${props.index}`))
                .then(() => {
                    console.log("");
                });
            <AdminPresenters state={false} />
            setIsOpen(false);
        } catch (error) {
            console.log(error);
        }
    }

    async function handelsave() {
        try {
            await update(ref(db, `presents/${props.index}`), {
                alt: `${title} picture`,
                name: title,
                text: text,
                image: props.image,
                tempImage: image,
                active: active
            });
            <AdminPresenters state={false} />
            setIsOpen(false);
        } catch (error) {
            console.log(error);
        }
    }


    return (
        <form className="mt-4 mb-4 flex flex-row justify-center">
            <div className="w-fit border shadow-xl rounded-3xl p-5 bg-neutral-300">
                <div className="grid grid-cols-3 w-full">
                    <div></div>
                    <div className="w-full flex container justify-center pb-2">
                        <label className="object-center rounded-full w-32 group">
                            <PlusIcon color="gray" className="w-32 z-10 invisible group-hover:visible absolute" />
                            <img src={image} className="rounded-full group-hover:blur-sm" alt={props.data.name + alt} />
                            <input type="file" name="image" onChange={handleChange} accept="image/png, image/jpeg" className="hidden" />
                        </label>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="w-full flex flex-col justify-center">
                            <BasicButton name="Save" variant="save" onClick={(event) => handleCheck(event, "Er du sikker på at du vil gemme?", "Save", "Cancel", "save")} />
                            <BasicButton name={active ? "Deactivate" : "Activate"} variant={active ? "save" : "close"} onClick={handleActivate} />
                        </div>
                        <div className="w-full flex flex-col justify-center">
                            <BasicButton name="Delete" variant="close" onClick={(event) => handleCheck(event, "Er du sikker på at du vil slette", "Yes", "Cancel", "delete")} />
                        </div>
                    </div>
                </div>
                <div className="flex flex-row justify-center">
                    <input className="max-w-xl w-full p-1 text-center h-auto rounded-2xl" value={title} onChange={handleChangeTitle} />
                </div>
                <div className="flex flex-row justify-center mx-10 my-5 bg-zinc-300 h-fit w-fit max-w-xl rounded-3xl">
                    <textarea onChange={handleChange} className="w-full p-1 h-auto rounded-2xl" value={text} rows={24} cols={90} />
                </div>
                <h2 className="font-akaya text-3xl italic text-center my-2">{title}</h2>
                <div className="flex flex-row justify-center mx-10 my-5 bg-zinc-300 h-fit w-fit max-w-xl rounded-3xl">
                    <p className="whitespace-pre-wrap">{text}</p>
                </div>
            </div>
            <SaveModal message={alertMessage} buttonOneText={buttonOneText} buttonTwoText={buttonTwoText} save={modalMode === "save" ? handelsave : handleDelete} isOpen={isOpen} setIsOpen={setIsOpen} />
        </form>
    );
}

