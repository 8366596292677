export const randomMesseges: string[] = [
    "Hey there! Welcome back to the team.",
    "Hi! It's great to see you back in the mix.",
    "Hey, you're back! We're excited to hear about your adventures.",
    "Hello, welcome back to the group!",
    "Hey there! We've missed your insights, welcome back.",
    "Hi, welcome back! We hope you had a great break.",
    "Welcome back! We hope you're feeling refreshed and ready to dive back in.",
    "Hey, you're back! We've been looking forward to catching up with you.",
    "Welcome back, it's good to see you!",
    "Hi there! Welcome back to the team.",
    "Hey, welcome back! We missed your energy around here.",
    "Welcome back! We hope you had a great time away.",
    "Hey there! It's good to see you again.",
    "Welcome back! We're excited to have you back on board.",
    "Hi! We hope you're feeling rejuvenated after your time off.",
    "Hey, welcome back! We're looking forward to hearing about your adventures.",
    "Welcome back! We hope you had a relaxing break.",
    "Hi there! It's great to have you back with us.",
    "Hey, you're back! We missed your positive attitude.",
    "Welcome back! We hope you're feeling recharged and ready to tackle new challenges.",
    "Hi! We've been looking forward to your return.",
    "Welcome back! We're so glad you're back in the fold.",
    "Hey there! It's good to see you back in action.",
    "Welcome back! We hope you had a wonderful time away.",
    "Hi! We've missed your contributions, welcome back.",
    "Hey, welcome back! We're ready to pick up where we left off.",
    "Welcome back! We're looking forward to your unique perspective.",
    "Hi there! We're glad to have you back with us.",
    "Hey, you're back! We're eager to hear about your time off.",
    "Welcome back! We hope you're ready for some new challenges.",
    "Hi! It's great to have you back in the mix.",
    "Hey there! We're excited to have you back on board.",
    "Welcome back! We hope you had a restful break.",
    "Hi there! We missed your company, welcome back.",
    "Hey, welcome back! We're ready to tackle new projects together.",
    "Welcome back! We're looking forward to collaborating with you again.",
    "Hi! We hope you had a great time off, welcome back.",
    "Hey there! It's good to have you back with us.",
    "Welcome back! We've missed your creativity and enthusiasm.",
    "Hi! We're excited to work with you again, welcome back.",
    "Hey, you're back! We're eager to catch up.",
    "Welcome back! We hope you had a fantastic break.",
    "Hi there! It's great to see you again.",
    "Hey, welcome back! We're ready to hit the ground running.",
    "Welcome back! We hope you're ready to jump back into things.",
    "Hi! We're glad you're back with us.",
    "Hey there! We're excited to have you back on the team.",
    "Welcome back! We missed your sense of humor and positive attitude.",
    "Hi! We hope you had a refreshing break, welcome back.",
    "Hey, you're back! We're looking forward to catching up on what we missed.",
    "Welcome back! We hope you're feeling energized and ready to tackle new challenges.",
    "Hi there! We're glad to have you back in the mix.",
    "Hey, welcome back! We're excited to have your skills back on board.",
    "Welcome back! We've missed your unique perspective and insights.",
    "Hi! We hope you had a great break and are ready to jump back in.",
    "Hey there! It's good to see you back in action.",
    "Welcome back! We're ready to continue working together towards our goals.",
    "Hi there! We're thrilled to have you back with us.",
    "Hey, you're back! We missed your positive impact on the team.",
    "Welcome back! We hope you had a rejuvenating break.",
    "Hi! We're excited to hear about your adventures, welcome back.",
    "Hey there! We're ready to collaborate with you again.",
    "Welcome back! We hope you're feeling refreshed and ready to go.",
    "Hi there! It's great to have you back in the mix.",
    "Hey, welcome back! We're looking forward to your contributions.",
    "Welcome back! We're excited to have your energy back in the office.",
    "Hi! We hope you had a great time off, welcome back.",
    "Hey there! We're ready to tackle new challenges together.",
    "Welcome back! We hope you had a wonderful break and are ready for new projects.",
    "Hi there! We're glad to have you back with us.",
    "Hey, you're back! We missed your expertise and leadership.",
    "Welcome back! We're eager to hear your ideas and insights.",
    "Hi! We hope you had a fantastic break, welcome back.",
    "Hey there! We're excited to have you back on the team.",
    "Welcome back! We've missed your can-do attitude and positivity.",
    "Hi there! It's great to see you back in the office.",
    "Hey, welcome back! We're looking forward to working with you again.",
    "Welcome back! We hope you're ready for new challenges and opportunities.",
    "Hi! We're thrilled to have you back in action, welcome back.",
    "Hey there! We missed your teamwork and collaboration, welcome back.",
    "Welcome back! We're excited to have your skills and expertise back on board.",
    "Hi there! It's good to have you back with us.",
    "Hey, you're back! We're ready to continue making progress together.",
    "Welcome back! We hope you had a restful break and are ready to tackle new projects.",
    "Hi! We're eager to hear about your time off and catch up, welcome back.",
    "Hey there! We're looking forward to your contributions and ideas.",
    "Welcome back! We missed your enthusiasm and passion for our work.",
    "Hi there! It's great to have you back with us, welcome back.",
    "Hey, welcome back! We're excited to have your perspective and insights.",
    "Welcome back! We hope you're feeling energized and ready to take on new challenges.",
    "Hi! We're ready to continue collaborating with you, welcome back.",
    "Hey there! We're thrilled to have you back on the team.",
    "Welcome back! We missed your dedication and hard work.",
    "Hi there! It's good to see you back in action, welcome back.",
    "Hey, you're back! We're looking forward to your unique contributions and expertise.",
    "Welcome back! We hope you had a great break and are ready to continue making progress together.",
    "Hi! We're eager to hear about your time off and welcome you back.",
    "Hey there! We're excited to see what you'll accomplish now that you're back.",
    "Welcome back! We're ready to tackle new challenges and opportunities together.",
    "Hi there! We're glad to have you back on the team.",
    "Hey, welcome back! We missed your creativity and problem-solving skills.",
    "Welcome back! We hope you had a wonderful break and are ready to continue contributing.",
    "Hi! We're looking forward to your energy and enthusiasm, welcome back.",
    "Hey there! We're excited to work with you again, welcome back.",
    "Welcome back! We missed your leadership and guidance.",
    "Hi there! It's great to have you back in the mix, welcome back.",
    "Hey, you're back! We're ready to continue building something great together.",
    "Welcome back! We hope you had a restful break and are ready to tackle new challenges with us.",
    "Hi! We're glad to have you back on board, welcome back.",
    "Hey there! We missed your passion and dedication, welcome back.",
    "Welcome back! We're looking forward to your ideas and contributions.",
    "Hi there! It's good to see you back in action, welcome back.",
    "Hey, welcome back! We're excited to continue working with you.",
    "Welcome back! We hope you had a great break and are ready to continue making progress together.",
    "Hi! We're eager to hear about your time off and catch up, welcome back.",
    "Hey there! We're looking forward to your contributions and ideas.",
    "Welcome back! We missed your enthusiasm and passion for our work.",
    "Hi there! It's great to have you back with us, welcome back.",
    "Hey, welcome back! We're excited to have your perspective and insights.",
    "Welcome back! We hope you're feeling energized and ready to take on new challenges.",
    "Hi! We're ready to continue collaborating with you, welcome back.",
    "Hey there! We're thrilled to have you back on the team.",
    "Welcome back! We missed your dedication and hard work.",
    "Hi there! It's good to see you back in action, welcome back.",
    "Hey, you're back! We're looking forward to your unique contributions and expertise.",
    "Welcome back! We hope you had a great break and are ready to continue making progress together.",
    "Hi! We're eager to hear about your time off and welcome you back.",
    "Hey there! We're excited to see what you'll accomplish now that you're back.",
    "Welcome back! We're ready to tackle new challenges and opportunities together.",
    "Hi there! We're glad to have you back on the team.",
    "Hey, welcome back! We missed your creativity and problem-solving skills.",
    "Welcome back! We hope you had a wonderful break and are ready to continue contributing.",
    "Hi! We're looking forward to your energy and enthusiasm, welcome back.",
    "Hey there! We're excited to work with you again, welcome back.",
    "Welcome back! We missed your leadership and guidance.",
    "Hi there! It's great to have you back in the mix, welcome back.",
    "Hey, you're back! We're ready to continue building something great together.",
    "Welcome back! We hope you had a restful break and are ready to tackle new challenges with us.",
    "Hi! We're glad to have you back on board, welcome back.",
    "Hey there! We missed your passion and dedication, welcome back."
]