
import routes from './routes';
import { useEffect } from 'react';
import { useLocation, useRoutes } from 'react-router-dom/dist';
import './App.css';
import './i18n';

function App() {
  const content = useRoutes(routes);

  const location = useLocation();

  useEffect(() => {
    document.querySelector('html')!.style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html')!.style.scrollBehavior = ''
  }, [location.pathname]); // triggered on route change
  console.log(`%c 
  ██████╗░░█████╗░███╗░░██╗██╗░██████╗██╗░░██╗
  ██╔══██╗██╔══██╗████╗░██║██║██╔════╝██║░░██║
  ██║░░██║███████║██╔██╗██║██║╚█████╗░███████║
  ██║░░██║██╔══██║██║╚████║██║░╚═══██╗██╔══██║
  ██████╔╝██║░░██║██║░╚███║██║██████╔╝██║░░██║
  ╚═════╝░╚═╝░░╚═╝╚═╝░░╚══╝╚═╝╚═════╝░╚═╝░░╚═╝
  
  ░██████╗██╗░░██╗██╗██████╗░░█████╗░██████╗░██╗
  ██╔════╝██║░░██║██║██╔══██╗██╔══██╗██╔══██╗██║
  ╚█████╗░███████║██║██████╦╝███████║██████╔╝██║
  ░╚═══██╗██╔══██║██║██╔══██╗██╔══██║██╔══██╗██║
  ██████╔╝██║░░██║██║██████╦╝██║░░██║██║░░██║██║
  ╚═════╝░╚═╝░░╚═╝╚═╝╚═════╝░╚═╝░░╚═╝╚═╝░░╚═╝╚═╝
  
  ███████╗███████╗░██████╗████████╗██╗██╗░░░██╗░█████╗░██╗░░░░░
  ██╔════╝██╔════╝██╔════╝╚══██╔══╝██║██║░░░██║██╔══██╗██║░░░░░
  █████╗░░█████╗░░╚█████╗░░░░██║░░░██║╚██╗░██╔╝███████║██║░░░░░
  ██╔══╝░░██╔══╝░░░╚═══██╗░░░██║░░░██║░╚████╔╝░██╔══██║██║░░░░░
  ██║░░░░░███████╗██████╔╝░░░██║░░░██║░░╚██╔╝░░██║░░██║███████╗
  ╚═╝░░░░░╚══════╝╚═════╝░░░░╚═╝░░░╚═╝░░░╚═╝░░░╚═╝░░╚═╝╚══════╝
  %c Made by Philip Guldborg
  `, 'color: #ff3600', 'color: #080808');

  return content;
}

export default App;
