import { IconProps } from "../../types/component.types";

export function UserPlusIcon(props: IconProps) {
    return (
        <div title={props.title ?? ""} className={props.className}>
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" fill="currentColor">
                <path d="M6.25,6.375C6.25,4.112 8.112,2.25 10.375,2.25C12.638,2.25 14.5,4.112 14.5,6.375C14.5,8.638 12.638,10.5 10.375,10.5C8.112,10.5 6.25,8.638 6.25,6.375ZM3.25,19.125C3.25,15.216 6.466,12 10.375,12C14.284,12 17.5,15.216 17.5,19.125L17.5,19.128L17.499,19.247C17.495,19.505 17.357,19.744 17.136,19.877C15.095,21.106 12.757,21.754 10.375,21.75C7.903,21.75 5.589,21.066 3.615,19.877C3.393,19.744 3.256,19.505 3.251,19.247L3.25,19.125Z" fill="none" strokeWidth="1px" stroke="black" />
                <path d="M19.75,7.5L19.75,9.75L22,9.75C22.411,9.75 22.75,10.089 22.75,10.5C22.75,10.911 22.411,11.25 22,11.25L19.75,11.25L19.75,13.5C19.75,13.911 19.411,14.25 19,14.25C18.589,14.25 18.25,13.911 18.25,13.5L18.25,11.25L16,11.25C15.589,11.25 15.25,10.911 15.25,10.5C15.25,10.089 15.589,9.75 16,9.75L18.25,9.75L18.25,7.5C18.25,7.089 18.589,6.75 19,6.75C19.411,6.75 19.75,7.089 19.75,7.5Z" />
            </svg>
        </div>

    )
}