import {useEffect, useState } from "react";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import { onValue, ref } from "firebase/database";
import { db } from "../../firebase";
import { FirebaseImageOptions } from "../../types/firebase.types";
import { ImageBlock } from "../../components/block/ImageBlock";

interface Props {
    state?: boolean;
}

export default function AdminVenue(props: Props) {
    const [data, setData] = useState(Object);
    const [sidebarOpen, setSidebarOpen] = useState(false);


    async function getImages() {
        const starCountRef = ref(db, 'urls/');
        await onValue(starCountRef, (snapshot) => {
            setData(snapshot.val());
        });

    }

    useEffect(() => {
        getImages();
    }, [])

    return (
        <div className="flex h-screen overflow-hidden">

            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />


            {/* Content area */}
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                {/*  Site header */}
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className="flex flex-col gap-5 justify-center">
                            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 auto-cols-max gap-4 m-2 md:m-20">
                                {Object.entries(data).map((keyName) => {
                                    const image = Object.call(data, keyName)
                                    const imageData: FirebaseImageOptions = image[1]
                                    return (
                                        <ImageBlock key={imageData.alt} alt={imageData.alt} picture={"data:image/png;base64," + imageData.image.base64} />
                                    )
                                }
                                )}
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}