import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';

import translationEng from './locales/en/translation.json'
import translationDk from './locales/dk/translation.json'


const lngDetector = new LanguageDetector(null, {
    order: ["localStorage", "cookie"],
    lookupCookie: "i18next",
    lookupLocalStorage: "i18next",
    caches: ["localStorage", "cookie"]
});

const resources = {
    en: {
        translation: translationEng
    },
    dk: {
        translation: translationDk
    }
}

i18n
    .use(lngDetector)
    .use(initReactI18next)
    .init({
        returnNull: false,
        resources,
        lng: 'en',
        fallbackLng: 'en',
        debug: false
    });


export default i18n;