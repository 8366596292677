import { IconProps } from "../../types/component.types";

export function BasicAuthIcon(props: IconProps) {
    return (
        <div title={props.title ?? ""} className={props.className}>
            <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                <path className="fill-current text-slate-600" d="M8.07 16H10V8H8.07a8 8 0 110 8z" />
                <path className="fill-current text-slate-400" d="M15 12L8 6v5H0v2h8v5z" />
            </svg>
        </div>

    )
}