import BannerBackground from "../pattens/BannerBackground";
import { randomMesseges } from "./RandomMesseges";

function WelcomeBanner() {
    const time: number = new Date().getHours();
    let message: string = "";
    const morningAfter5 = time >= 5;
    const morningBefore11 = time <= 11;

    const afternoonAfter12 = time >= 12;
    const afternoonBefore17 = time <= 17;

    const eveningAfter18 = time >= 18;
    const eveningBefore24 = time <= 24;
    const eveningAfter0 = time >= 0;
    const eveningBefore4 = time <= 4;

    function getRandomInt(max: number) {
        return Math.floor(Math.random() * max);
    }


    if (morningAfter5 && morningBefore11) {
        message = "Good Morning";
    }
    if (afternoonAfter12 && afternoonBefore17) {
        message = "Good Afternoon";
    }
    if ((eveningAfter18 && eveningBefore24) || (eveningAfter0 && eveningBefore4)) {
        message = "Good Evening";
    }

    return (
        <div className="relative bg-indigo-200 p-4 sm:p-6 rounded-sm overflow-hidden mb-8">

            {/* Background illustration */}
            <BannerBackground />

            {/* Content */}
            <div className="relative">
                <h1 className="text-2xl md:text-3xl text-slate-800 font-bold mb-1">{message}, Admin Inc. 👋</h1>
                <p>{randomMesseges[getRandomInt(140)]}</p>
            </div>

        </div>
    );
}

export default WelcomeBanner;
