export default function processImage(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event: ProgressEvent<FileReader>) => {
            const base64Image = event.target?.result as string;
            resolve(base64Image);
        };

        reader.onerror = (event: ProgressEvent<FileReader>) => {
            reject(new Error('Failed to read the file'));
        };

        const image = new Image();
        image.onload = () => {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            if (!context) {
                reject(new Error('Canvas context is not supported'));
                return;
            }

            const maxDimension = 800; // Maximum dimension for the compressed image
            let width = image.width;
            let height = image.height;

            if (width > height) {
                if (width > maxDimension) {
                    height *= maxDimension / width;
                    width = maxDimension;
                }
            } else {
                if (height > maxDimension) {
                    width *= maxDimension / height;
                    height = maxDimension;
                }
            }

            canvas.width = width;
            canvas.height = height;
            context.drawImage(image, 0, 0, width, height);

            const compressedBase64Image = canvas.toDataURL('image/jpeg', 0.7); // Adjust the quality level as desired (0.7 in this example)
            resolve(compressedBase64Image);
        };

        image.onerror = () => {
            reject(new Error('Failed to load the image'));
        };

        image.src = URL.createObjectURL(file);
    });
}