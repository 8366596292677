import { Dispatch, MouseEventHandler, SetStateAction, useEffect, useRef } from "react";
import { BasicButton } from "../BasicButton";

interface Props {
    save: MouseEventHandler<HTMLButtonElement>;
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    buttonOneText: string;
    buttonTwoText: string;
    message: string;
}

export default function SaveModal(props: Props) {
    const dialogRef = useRef<HTMLDialogElement>(null);

    useEffect(() => {
        props.isOpen ? dialogRef.current?.showModal() : dialogRef.current?.close();
    }, [props.isOpen]);

    return (
        <dialog ref={dialogRef} className="bg-neutral-300 border shadow-xl rounded-xl w-96">
            <h1 className="mb-2 text-center">{props.message}</h1>
            <div className="flex justify-center gap-2">
                <BasicButton variant="save" name={props.buttonOneText} className="bg-green-500" onClick={props.save} />
                <BasicButton name={props.buttonTwoText} variant="close" onClick={() => props.setIsOpen(false)} />
            </div>
        </dialog>
    )
}