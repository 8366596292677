import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import { onValue, ref, update } from "firebase/database";
import { db } from "../../firebase";
import { FirebaseObject } from "../../types/firebase.types";
import { BasicButton } from "../../components/BasicButton";
import SaveModal from "../../components/modal/Save";
import { Popup } from "../../components/modal/Popup";

interface Props {
    state?: boolean;
}

export default function AdminRules(props: Props) {
    const [data, setData] = useState<Omit<FirebaseObject, 'id'>>({ text: "" });
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [showPopup, setShowPopup] = useState(false);


    async function getRules() {
        const starCountRef = ref(db, 'rules/');
        await onValue(starCountRef, (snapshot) => {
            setData(snapshot.val());
        })
    }

    useEffect(() => {
        if (props.state) {
            setSidebarOpen(!sidebarOpen);
        }
        getRules();
        // eslint-disable-next-line
    }, [])

    function onChange(event: ChangeEvent<HTMLInputElement>) {
        setData({
            ...data,
            [event.target.name]: event.target.value
        })
    }

    async function handelsave() {
        try {
            await update(ref(db, `rules`), data);
            <AdminRules state={false} />
        } catch (error) {
            console.log(error);
        }
        setIsOpen(false);
        setShowPopup(true);
    }

    const handleCheck = (event: FormEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setIsOpen(true);
    };

    return (
        <div className="flex h-screen overflow-hidden">

            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />


            {/* Content area */}
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                {/*  Site header */}
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} >
                    {showPopup && <Popup message="Successful saved" timeout={5000} isOpen={showPopup} setIsOpen={setShowPopup} onClose={() => setShowPopup(false)}/>}
                </Header>

                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className="flex flex-col gap-5 justify-center">
                            {Object.entries(data).map((keyName) => {
                                const arrayOfPresentsData = Object.call(data, keyName);
                                return (
                                    <form key={arrayOfPresentsData[0]} className="flex flex-row gap-3">
                                        <label className="text-lg font-bold">{arrayOfPresentsData[0]}:</label>
                                        <input className="border rounded w-full" type="text" name={arrayOfPresentsData[0]} value={arrayOfPresentsData[1]} onChange={onChange} />
                                    </form>
                                );
                            }
                            )}
                            <SaveModal message="Er du sikker på at du vil gemme?" buttonOneText="Save" buttonTwoText="Cancel" save={handelsave} isOpen={isOpen} setIsOpen={setIsOpen} />
                        </div>
                    </div>
                    <div className="w-full flex flex-col justify-center">
                        <BasicButton name="Save" variant="save" onClick={handleCheck} />
                    </div>
                </main>
            </div>
        </div>
    );
}