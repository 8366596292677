import clsx from "clsx";
import { MouseEventHandler } from "react";

interface Props {
    className?: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    name?: string;
    variant: ButtonVarient;
    icon?: JSX.Element;
    type?: "submit";
    disabled?: boolean;
}

const varients = {
    save: `
    bg-gradient-to-r
    hover:bg-gradient-to-br
    from-green-400
    via-green-500
    to-green-600
    focus:ring-green-300
    shadow-green-500/50
    `,
    close: `
    bg-gradient-to-r
    hover:bg-gradient-to-br
    from-red-400 
    via-red-500 
    to-red-600  
    focus:ring-red-300 
    shadow-red-500/50
    `,
    default: `
    bg-gradient-to-r
    hover:bg-gradient-to-br
    from-blue-400 
    via-blue-500 
    to-blue-600  
    focus:ring-blue-300 
    shadow-blue-500/50
    `
}

export type ButtonVarient = keyof typeof varients;

export function BasicButton(props: Props) {
    function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        if (props.onClick) {
            props.onClick(event);
        }
    };

    return (
        <div className="flex flex-row justify-center">
            {
                props.type === "submit"
                    ?
                    <div className={clsx("h-fit p-2 w-24 focus:ring-4 focus:outline-none shadow-lg font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 bg-gradient-to-r hover:bg-gradient-to-br flex flex-row gap-1 justify-center",
                        varients[props.variant],
                        props.className
                    )}>
                        <input type="submit" value={props.name} />
                    </div>
                    :
                    <button onClick={handleClick} type={props.type ? props.type : "button"} disabled={props.disabled} className={clsx("h-fit p-2 w-24 focus:ring-4 focus:outline-none shadow-lg font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 bg-gradient-to-r hover:bg-gradient-to-br flex flex-row gap-1 justify-center",
                        varients[props.variant],
                        props.className
                    )}>
                        {props.icon ?? ""}
                        {props.name}
                    </button>
            }

        </div>
    );
}