import SidebarLinkGroup from "./SidebarLinkGroup";
import React, { useState } from 'react';
import { ArrowIcon } from "../Icons/Arrow";
import { AdminSideBarHeader } from "../text/Header";
import BasicUnderElement from "./BasicUnderElement";
import { BasicUnderElementLink } from "./BasicUnderElementLink";
import { useLocation } from "react-router-dom";

interface UnderElemt {
    urls: string;
    underElemtext: string;
}
interface Props {
    type: "singel" | "multi";
    pathname: string;
    linkTo: string;
    underElemt: UnderElemt[];
    title: string;
    icon: any;
}

export default function SideBarElementGroup(props: Props) {
    const location = useLocation();
    const { pathname } = location;

    const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
    const [sidebarExpanded, setSidebarExpanded] = useState(storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true');

    return (
        <SidebarLinkGroup activecondition={pathname === '/' || pathname.includes(props.pathname)}>
            {(handleClick: () => any, open: boolean) => {
                return (
                    <React.Fragment>
                        <a
                            href={props.linkTo}
                            className={`block text-slate-200 truncate transition duration-150 ${pathname === '/' || pathname.includes(props.pathname) ? 'hover:text-slate-200' : 'hover:text-white'}`}
                            onClick={(e) => {
                                e.preventDefault();
                                sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                            }}
                        >
                            <div className="flex items-center justify-between">
                                <AdminSideBarHeader text={props.title} icon={props.icon} />
                                <ArrowIcon open={open} />
                            </div>
                        </a>
                        <BasicUnderElement open={open}>
                            {props.underElemt.forEach((element: any) => {
                                if (element) {
                                    return (<BasicUnderElementLink key={element[0] + element[1]} text={element[0]} url={element[1]} />);
                                }

                            })}
                        </BasicUnderElement>
                    </React.Fragment>
                );
            }}
        </SidebarLinkGroup>
    );
}