import { NavLink, useLocation } from "react-router-dom";

interface Props {
    icon: any;
    pathname: string;
    title: string;
    url: string;
}

export default function SideBarLink(props: Props) {
    const location = useLocation();
    const { pathname } = location;

    return (
        <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes(props.pathname) && 'bg-slate-900'}`}>
            <NavLink
                end
                to={props.url}
                className={`block text-slate-200 truncate transition duration-150 ${pathname.includes(props.pathname) ? 'hover:text-slate-200' : 'hover:text-white'
                    }`}
            >
                <div className="flex items-center">
                    {props.icon}
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        {props.title}
                    </span>
                </div>
            </NavLink>
        </li>
    );
}