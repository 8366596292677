import { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";
import { LoginForm } from "../../components/login/Form";
import { LoginDescription } from "../../components/login/Description";
import { useNavigate } from "react-router-dom/dist";
import { validate } from "../../services/FormValidate";

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    function handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
        setEmail(event.target.value);
    }

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    async function handleLogin(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        try {
            await signInWithEmailAndPassword(auth, email, password).then((userCredential) => {
                handleLoginSuccess();
            }).catch((error) => {
                const errorCode = error.code;
                validate("email", errorCode);
            });
        } catch (error) {
            console.error(error);
        }
    }

    function handleLoginSuccess() {
        return navigate("/admin/fNhzjfq1Jrd8GgkQ6w3x");
    }

    return (
        <section className="h-full flex justify-center">
            <div className="container h-full p-10">
                <div className="g-6 flex h-full flex-wrap items-center justify-center text-neutral-800">
                    <div className="block rounded-lg bg-white shadow-lg">
                        <div className="g-0 lg:flex lg:flex-wrap">
                            <LoginForm title="Please login to your account" OnSubmit={handleLogin} email={email} password={password} onChangeEmail={handleEmailChange} onChangePassword={handlePasswordChange} />
                            <LoginDescription text="We are more than just a festival" title='"Danish Shibari festival" is the only Shibari festival in Denmark and we are proud of it. On the festival place we have Denmarks most beautiful nature and lot of space, to get your inner Shibari idears out. So they downt get lost forever.' />
                        </div>
                    </div>
                </div>
            </div >
        </section >
    );
};

export default Login;